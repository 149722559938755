import React from 'react'
import HeroBackground from './background-images/index'
import { Link } from 'gatsby'

import '../css/hero.css'

const Hero = () => (
  <HeroBackground className="hero -mt-12 pb-16">
    <div className="container mx-auto">
      <div className="flex mb-4 text-white items-center">
        <div className="w-full md:w-1/2 md:ml-auto text-center">
          <span className="block pt-48 text-5xl text-display text-shadow uppercase">
            Jump. Play. Grow.
          </span>
          <span className="block pt-8">
            <Link
              to="/classes"
              className="bg-green-dark hover:bg-green text-white uppercase font-bold py-3 px-16 rounded-full no-underline text-xl shadow-md hover:shadow-lg text-shadow transition-all"
            >
              Register
            </Link>
          </span>
        </div>
      </div>
    </div>
  </HeroBackground>
)

export default Hero
