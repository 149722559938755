import React from 'react'
import Image from './images/index'

const AboutUs = () => (
  <section className="bg-white py-16">
    <div className="container flex flex-wrap lg:flex-row-reverse mx-auto px-4 md:px-0">
      <div className="w-full lg:w-1/2 pl-0 lg:pl-6 mb-8 mt-2 text-center">
        <Image className="picture-frame" src="coachImage" aspectRatio="fluid" alt="Coach and owner Ben O&apos;Neill smiling and giving pep talk to team at a gymnastics competition"/>
      </div>
      <div className="w-full lg:w-1/2">
        <h1 className="font-light pb-4">About Us</h1>
        <p className="text-grey-darker font-light">
          Jump Around Gymnastics provides gymnastics instruction, open gyms, field trips and birthday parties. Our facility has all Olympics apparatuses for boys and girls, as well as preschool equipment and trampolines.
        </p>
        <h2 className="font-light pt-8 pb-4">Our Mission &amp; Goal</h2>
        <p className="text-grey-darker font-light">
          Our mission is to provide a positive gymnastics experience that creates a life-long love for the sport. Our goal is for gymnastics to provide your child benefits far beyond the gym. Lessons learned in gymnastics can have lasting effects on your child&rsquo;s life.
        </p>
        <h2 className="font-light pt-8 pb-4">About the Owner</h2>
        <p className="text-grey-darker font-light">
          Owner Ben O&rsquo;Neill has over 2 decades of coaching experience at the Preschool, Recreational, Junior Olympic and College levels. Born and raised in Madison, Wisconsin, Ben earned a gymnast scholarship at Southern Connecticut State University where he trained under Olympic Gymnast and Hall of Fame Coach, Abie Grossfeld. Ben also earned All-American Honors as a Springboard Diver. He continued his coaching career as the Head Diving Coach at Delta State University while earning his Masters Degree in Education. After serving as Gymnastics Coordinator for the Evergreen Parks and Recreation District in Evergreen, Colorado he moved back to Madison to open Jump Around.
        </p>
      </div>
    </div>
  </section>
)

export default AboutUs
