import React from 'react'
import CenterBlock from './center-block'
import Image from './images/index'
import Container from './container'
import CardRow from './card-row'
import Card from './card'
import SessionList from './session-list'

const OfferingBlocks = () => (
  <section>
    <Container>
      <div className="mt-16">
        <CenterBlock>
          <h1 className="font-light pb-8">Welcome to Jump Around Gymnastics</h1>
          <p className="text-grey-darker font-light">
            Jump Around Gymnastics is DeForest&rsquo;s home for preschool,
            recreational and competitive gymnastics! Opened in 2012, we provide
            a fun and positive atmosphere for children to learn. Our staff is
            dedicated to developing the physical, social, and emotional
            well-being of gymnasts. We also offer special activities including
            open jump, field trips, and birthday parties.
          </p>
        </CenterBlock>
      </div>
      <CardRow>
        <Card to="/classes" classes="zoom">
          <Image
            src="classesImage"
            aspectRatio="fluid"
            alt="Toddler practicing foot placement, balance, and coordination in Kangaroos class"
          />
          <header className="flex items-center bg-white leading-tight p-2 md:p-4">
            <h1 className="mx-auto text-display text-purple">Classes</h1>
          </header>
          <div className="flex items-center bg-white leading-none">
            <p className="text-purple font-bold py-4 mx-auto text-center">
              Classes for ages 18 months to 6+ years
            </p>
          </div>
          <footer className="flex items-center p-2 md:p-4 bg-white">
            <div className="text-grey-darker font-bold mx-auto text-center h-32">
              {/* Upcoming Sessions */}
              <ul className="list-reset font-normal leading-none">
                <SessionList liClassName={'my-2'} />
              </ul>
            </div>
          </footer>
        </Card>
        <Card to="/open-jump" classes="zoom">
          <Image
            src="openJumpImage"
            aspectRatio="fluid"
            alt="Young girl having fun jumping onto gymnastics landing mat"
          />
          <header className="flex items-center bg-white leading-tight p-2 md:p-4">
            <h1 className="mx-auto text-display text-orange">Open Jump</h1>
          </header>
          <div className="flex items-center bg-white leading-none">
            <p className="text-orange font-bold py-4 mx-auto text-center">
              Safe, fun, and open to all ages
            </p>
          </div>
          <footer className="grid grid-rows items-center p-2 md:p-4 bg-white">
            <div className="text-grey-darker mx-auto text-center h-32">
              <span className="text-display text-lg">DeForest</span>
              <ul className="list-reset font-normal leading-none">
                <li className="my-2">
                  <span className="font-bold">Tues/Thurs:</span> 12pm &ndash;
                  1pm
                </li>
                <li className="my-2">
                  <span className="font-bold">Fri:</span> 7:30pm &ndash; 8:30pm
                </li>
                <li className="my-2">
                  <span className="font-bold">Sat:</span> 12pm &ndash; 1pm
                </li>
              </ul>
            </div>
            <div className="text-grey-darker font-bold mx-auto text-center h-32">
              <span className="text-display text-lg">Cottage Grove</span>
              <ul className="list-reset font-normal leading-none">
                <li className="my-2">
                  <span className="font-bold">Tues/Thurs:</span> 10am &ndash;
                  12pm
                </li>
                <li className="my-2">
                  <span className="font-bold">Fri:</span> 10am &ndash; 12pm
                </li>
                <li className="my-2">
                  <span className="font-bold">Sat:</span> 10am &ndash; 12pm
                </li>
              </ul>
            </div>
          </footer>
        </Card>
        <Card to="/parties" classes="zoom">
          <Image
            src="partiesImage"
            aspectRatio="fluid"
            alt="Three young girls smiling and playing under gymnastics donut"
          />
          <header className="flex items-center bg-white leading-tight p-2 md:p-4">
            <h1 className="mx-auto text-display text-green">Parties</h1>
          </header>
          <div className="flex items-center bg-white leading-none">
            <p className="text-green font-bold py-4 mx-auto text-center">
              Celebrate your special day with us
            </p>
          </div>
          <footer className="flex items-center p-2 md:p-4 bg-white">
            <div className="text-grey-darker font-bold mx-auto text-center h-32">
              Parties for all ages are welcome
              <p className="text-grey-darker font-light mt-4">
                Host the ultimate birthday party.
                <br />
                Our foam pit is always a hit, and <br />
                we will ensure safety and fun!
              </p>
            </div>
          </footer>
        </Card>
      </CardRow>
    </Container>
  </section>
)

export default OfferingBlocks
