import React from 'react'
import Slider from "react-slick"

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Testimonials = () => (
  <section className="py-16">
    <div className="container mx-auto">
      <div className="flex items-center">
        <div className="w-full md:w-2/3 mx-auto text-center px-4 md:px-0">
          <h1 className="font-light">What People Are Saying About Us</h1>
          <Slider {...sliderSettings}>
            <div className="py-8">
              <p className="text-grey-darker font-light italic">&ldquo;I can&apos;t recommend this place enough! My 3 year old has so much fun playing on all the equipment, especially the foam pit, and loves her coaches. They are so encouraging and are always proud of all their students&apos; accomplishments. Such a great way for my kid to learn new skills and make new friends.&rdquo;</p>
            </div>
            <div className="py-8">
              <p className="text-grey-darker font-light italic">&ldquo;Children learn great skills in a positive, caring, and safe environment. The coaches are outstanding, and a wonderful influence on the kids. Love this place.&rdquo;</p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  </section>
)

export default Testimonials
