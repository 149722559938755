import React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import OfferingBlocks from '../components/offering-blocks'
import AboutUs from '../components/about-us'
import Testimonials from '../components/testimonials'
import Footer from '../components/footer'
import Copyright from '../components/copyright';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Jump Around Gymnastics | DeForest & Cottage Grove"
      keywords={[
        'jump around gymnastics',
        'gymnastics',
        'gymnastics classes',
        'open jump',
        'open gym',
        'gymnastics madison',
        'gymnastics deforest',
        'gymnastics sun prairie',
        'gymnastics fall river',
        'gymnastics pardeeville',
        'gymnastics cottage grove',
        'summer camp',
        'kids activities',
      ]}
      description={`Madison area's home for preschool, recreational, and competitive gymnastics. Offering gymnastics classes, open gym, and hosted parties.`}
    />
    <Hero></Hero>
    <OfferingBlocks></OfferingBlocks>
    <AboutUs></AboutUs>
    <Testimonials></Testimonials>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default IndexPage
